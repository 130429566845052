// DataContext.js
import React, { createContext, useState, useEffect } from 'react';

const DataContext = createContext();

const DataProvider = ({ children }) => {
  const [jobs, setjobs] = useState();


  return (
    <DataContext.Provider value={{ jobs, setjobs }}>
      {children}
    </DataContext.Provider>
  );
};

export { DataContext, DataProvider };
