import React, { useEffect, useState, useContext } from "react";
import PropTypes from "prop-types";
import Container from "react-bootstrap/Container";
import AccordionComponent from "../../components/Accordian";
import Footer from "../../components/Footer";
import "../../styles/privacypolicy.css";

const Faq = (props) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const faqs = [
    {
      title: "What is Appoint Talent?",
      des: "Appoint Talent is a platform designed to help users find job opportunities and scholarships tailored to their needs and qualifications.",
    },
    {
      title: "How does Appoint Talent work?",
      des: "We aggregate job listings and scholarship opportunities from various sources, allowing users to search and apply for them easily.",
    },
    {
      title: "Is Appoint Talent free to use?",
      des: "Yes, our platform is completely free for users.",
    },
    {
      title: "How do I search for jobs?",
      des: "Use the search bar to enter keywords, job titles, or company names. You can also filter results by location, job type, and industry.",
    },
    {
      title: "How do I apply for a job?",
      des: "Click on the job listing and follow the application instructions provided. Some jobs may require you to apply directly through the employer’s website.",
    },
    {
      title: "How do I search for scholarships?",
      des: "Use the scholarship search tool to enter keywords or filter by criteria such as location, field of study, and eligibility.",
    },
    {
      title: "How do I apply for scholarships?",
      des: "Click on the scholarship listing and follow the application instructions provided. Some scholarships may require you to apply directly through the sponsor’s website.",
    },
    {
      title: "What should I do if I encounter a technical issue?",
      des: "Please contact our support team via the 'Contact Us' page or email support@appointtalent.com. Include as much detail as possible about the issue you are experiencing.",
    },
    {
      title: "Why am I not receiving email notifications?",
      des: "Check your spam/junk folder and make sure notifications@appointtalent.com is added to your email contacts. Also, verify that your notification settings are correctly configured in your account.",
    },
    {
      title: "How can I contact customer support?",
      des: "You can contact our customer support team through the 'Contact Us' page or by emailing support@appointtalent.com",
    },
    {
      title: "Where can I find more information about Appointtalent?",
      des: "Visit our About Us page to learn more about our mission, vision, and the team behind Appointtalent.",
    },
  ];
  return (
    <>
      <Container className="_main_section" style={{ marginTop: 100 }}>
        {faqs.map((val, i) => (
          <AccordionComponent data={val} key={i} index={i} />
        ))}
      </Container>
      <br />
      <br />
      <Footer />
    </>
  );
};

export default Faq;
