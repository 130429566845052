import "./App.css";
import "./index.css";
import React from "react";
import Navbar from "./components/Navbar";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import LoadingBar from "react-top-loading-bar";
import { useState } from "react";
import Home from "./pages/home";
import Details from "./pages/details";
import Contact from "./pages/contact";
import PrivacyPolicy from "./pages/privacy-policy";
import NotFound from "./pages/notFound";
import Jobs from "./pages/Jobs";
import Search from "./pages/Search";
import TermsAndConditions from "./pages/terms-and-conditions";
import Faq from "./pages/faq";

// import { JobsProvider } from './JobsContext';
const App = () => {
  const pageSize = 9;
  const apiKey = "a19b01428emshc8ebc3d1d7714c5p19523djsn73665243edaa";
  const [progress, setProgress] = useState(0);

  return (
    <div>
      <Router>
        <Navbar />
        <div>
          <LoadingBar color="#f11946" progress={progress} height={3} />
        </div>
        <Routes>
          <Route
            exact
            path="/"
            element={
              <Home
                setProgress={setProgress}
                apiKey={apiKey}
                key="general"
                pageSize={pageSize}
                country="in"
                category="general"
              />
            }
          />

          <Route
            path="/jobs"
            element={
              <Jobs
                setProgress={setProgress}
                apiKey={apiKey}
                key="general"
                pageSize={pageSize}
                country="in"
                category="general"
              />
            }
          />

          <Route
            exact
            path="/contact"
            element={
              <Contact
                setProgress={setProgress}
                apiKey={apiKey}
                key="general"
                pageSize={pageSize}
                country="in"
                category="general"
              />
            }
          />
          <Route
            exact
            path="/privacy-policy"
            element={
              <PrivacyPolicy
                setProgress={setProgress}
                apiKey={apiKey}
                key="general"
                pageSize={pageSize}
                country="in"
                category="general"
              />
            }
          />
          <Route exact path="/*" element={<NotFound />} />
          <Route
            exact
            path="/terms-of-service"
            element={<TermsAndConditions />}
          />
          <Route exact path="/faq" element={<Faq />} />
          <Route
            exact
            path="/details"
            element={
              <Details
                setProgress={setProgress}
                apiKey={apiKey}
                key="general"
                pageSize={pageSize}
                country="in"
                category="general"
              />
            }
          />
          <Route
            exact
            path="/search"
            element={
              <Search
                setProgress={setProgress}
                apiKey={apiKey}
                key="general"
                pageSize={pageSize}
                country="in"
                category="general"
              />
            }
          />
        </Routes>
      </Router>
    </div>
  );
};

export default App;
