import React, { useEffect } from 'react';

const GoogleAd = ({ client, slot, format, className, style }) => {
  useEffect(() => {
    const loadAds = () => {
      try {
        (window.adsbygoogle = window.adsbygoogle || []).push({});
      } catch (e) {
        console.error("AdSense error", e);
      }
    };

    if (window) {
      if (window.adsbygoogle && window.adsbygoogle.length > 0) {
        loadAds();
      } else {
        const script = document.createElement("script");
        script.src = "https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-3768976581935456";
        script.async = true;
        script.onload = loadAds;
        document.body.appendChild(script);
      }
    }
  }, []);

  return (
    <ins
      className={`adsbygoogle ${className}`}
      style={style}
      data-ad-client={client}
      data-ad-slot={slot}
      data-ad-format={format}
      
    ></ins>
  );
};

export default GoogleAd;
