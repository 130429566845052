import React from "react";
import "../styles/home.css";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { MdOutlineOpenInNew } from "react-icons/md";
import { MdKeyboardDoubleArrowRight } from "react-icons/md";
import { HiMiniBuildingOffice2 } from "react-icons/hi2";

const JobItem = (props) => {
  const navigate = useNavigate();
  let {
    employer_logo,
    employer_name,
    job_title,
    job_country,
    job_city,
    job_posted_at_datetime_utc,
    description,
    job_is_remote,
    job_employment_type,
    id,
    job_type,
    createdAt,
  } = props.data;
  console.log(id);
  return (
    <Card className="_job_view" onClick={() => navigate(`/details?id=${id}`)}>
      <div className="_card_body">
        {/* <Card.Img
            className="_job_image"
            // variant="top"
            src={employer_logo}
          /> */}

        {employer_logo ? (
          <img src={employer_logo} className="_job_image" />
        ) : (
          <div className="_job_image">
            <HiMiniBuildingOffice2 size={40} color="#0a66c2" />
          </div>
        )}
        <div className="_right_section">
          <h3 className="_job_title">{job_title}</h3>
          <h4>
            <a href="#" className="_company_name">
              {employer_name}
            </a>
          </h4>
          <div className="_job_details">
            <div>
              <span className="_job_location">
                {job_country},{job_city}
              </span>
              <div className="job_type_sec">
                <div>{job_type && job_type}</div>
                <div>{job_employment_type}</div>
              </div>
            </div>
            <div></div>
            <div>
              <span className="_posting_time">
                {moment(createdAt.toDate())
                  .fromNow()
                  .replace("a few seconds ago", "Just now")
                  .replace("a year ago", "1 year ago")
                  .replace("a minute ago", "1 minute ago")
                  .replace(/minutes ago/, "minutes ago")
                  .replace("an hour ago", "1 hour ago")
                  .replace(/hours ago/, "hours ago")
                  .replace("a day ago", "1 day ago")
                  .replace(/days ago/, "days ago")}
              </span>
            </div>
          </div>
        </div>
      </div>

      <Card.Body></Card.Body>
      <Card.Footer>
        {/* <Card.Text className="truncate">{truncateDescription(description)}</Card.Text> */}
        <div
          className="truncate"
          dangerouslySetInnerHTML={{ __html: description }}
        />
        <div className="_card_footer">
          <button
            className="_apply_btn"
            style={{ fontSize: 12, marginTop: 0, height: 30 }}
          >
            Details
            <MdKeyboardDoubleArrowRight />
          </button>
        </div>
      </Card.Footer>
    </Card>
  );
};

export default JobItem;
