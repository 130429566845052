import Accordion from "react-bootstrap/Accordion";
import "./../styles/accordian.css";

function AccordionComponent({ data, index }) {
  let { title, des } = data;
  return (
    <Accordion style={{ marginTop: 20 }}>
      <Accordion.Item eventKey={index}>
        <Accordion.Header>{title}</Accordion.Header>
        <Accordion.Body>{des}</Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
}

export default AccordionComponent;
