import React, { useEffect, useState, useContext } from "react";
import PropTypes from "prop-types";
import Container from "react-bootstrap/Container";
import { GrLocation } from "react-icons/gr";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
// import { DataContext } from "../../context/DataContext";
import Footer from "../../components/Footer";

import "../../styles/contact.css";

const Contact = (props) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Container className="_main_section">
        <h1 className="contactUsHeading">Contact us</h1>
        <Row className="justify-content-center mt-10">
          {/* <Col xl="6" lg="6" md="6" sm="12" xs="12">
            <div>
              <div>
                <GrLocation color="#000" size={30} />
                <div>Jaddish Colony Mithi</div>
              </div>
            </div>
          </Col> */}
          <Col xl="10" lg="10" md="12" sm="12" xs="12">
            <div className="inputDiv">
              <input type="text" placeholder="Name" className="input" />
              <input type="email" placeholder="Email" className="input" />
            </div>
            {/* <div>
              <input type="email" placeholder="EMAIL" className="input" />
            </div> */}
            <div>
              <textarea
                placeholder="Message"
                style={{ paddingTop: 10, height: 150, width: "100%" }}
                className="input"
              ></textarea>
            </div>
            <div style={{ marginTop: 20, display: "flex", flexDirection: "" }}>
              <button className="submitBtn">Submit</button>
            </div>
          </Col>
        </Row>
      </Container>
      <br />
      <br />
      <Footer />
    </>
  );
};

export default Contact;

Contact.defaultProps = {
  country: "in",
  pageSize: 16,
  category: "general",
};

Contact.propTypes = {
  country: PropTypes.string,
  pageSize: PropTypes.number,
  category: PropTypes.string,
};
