import React, { useEffect, useState, useContext } from "react";
import PropTypes from "prop-types";
import Container from "react-bootstrap/Container";
import Footer from "../../components/Footer";
import "../../styles/privacypolicy.css";

const TermsAndConditions = (props) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Container className="_main_section">
        <h1 className="contactUsHeading">Terms Of Service</h1>
        <div className="policyDate">Last Updated: March 1, 2024</div>
        <p className="thanksMessageTxt">
          Thank you for using our Job Finder website ("Website"). This Privacy
          Policy outlines how we collect, use, disclose, and protect your
          information when you use our services.
        </p>
        <h3 className="heading">1. Information We Collect</h3>
        <p className="policyTxt">
          <b>1.1</b> Personal Information: We collect personal information you
          provide to us, including but not limited to your name, email address,
          phone number, resume/CV, employment history, and any other information
          you choose to provide when using our Website.
        </p>
        <p className="policyTxt">
          <b>1.2</b> Usage Information: We may collect information about how you
          use our Website, including your browsing actions, pages visited, links
          clicked, and other interactions with the Website.
        </p>
        <p className="policyTxt">
          <b>1.3</b> Usage Information: We may collect information about how you
          use our Website, including your browsing actions, pages visited, links
          clicked, and other interactions with the Website.
        </p>
        <p className="policyTxt">
          <b>1.4</b> Cookies and Similar Technologies: We may use cookies, web
          beacons, and similar technologies to collect information about your
          use of the Website and to improve your experience. You can manage your
          cookie preferences through your browser settings.
        </p>
        <h3 className="heading">2. How We Use Your Information</h3>
        <p className="policyTxt">
          <b>2.1</b> Provide and Improve Services: We use the information we
          collect to provide and improve our services, personalize your
          experience, and optimize the functionality of the Website.
        </p>
        <p className="policyTxt">
          <b>2.2</b> Communication: We may use your contact information to
          communicate with you about your account, respond to inquiries, send
          updates, and provide information about our services.
        </p>
        <p className="policyTxt">
          <b>2.3</b> Analytics: We may use aggregated and anonymized data for
          analytics purposes to understand trends, track user interactions, and
          improve the Website.
        </p>
        <p className="policyTxt">
          <b>2.4 </b> Legal Compliance: We may use your information to comply
          with applicable laws, regulations, and legal processes, or to protect
          our rights, property, or safety and that of others.
        </p>
        <h3 className="heading">3. Information Sharing</h3>
        <p className="policyTxt">
          <b>3.1</b> Service Providers: We may share your information with
          third-party service providers who assist us in operating the Website,
          conducting our business, or servicing you, subject to confidentiality
          obligations.
        </p>
        <p className="policyTxt">
          <b>3.2</b> Legal Requirements: We may disclose your information if
          required to do so by law or in response to valid requests by public
          authorities (e.g., a court or government agency).
        </p>
        <p className="policyTxt">
          <b>3.3</b> Business Transfers: In the event of a merger, acquisition,
          or sale of all or a portion of our assets, your information may be
          transferred as part of the transaction.
        </p>
        <h3 className="heading">4. Data Security</h3>
        <p className="policyTxt">
          We take reasonable measures to protect your information from
          unauthorized access, disclosure, alteration, or destruction. However,
          please note that no method of transmission over the internet or
          electronic storage is 100% secure, and we cannot guarantee absolute
          security.
        </p>
        <h3 className="heading">5. Your Choices</h3>
        <p className="policyTxt">
          You may choose not to provide certain personal information, but this
          may limit your ability to access certain features of the Website. You
          can also opt-out of receiving promotional communications from us by
          following the instructions provided in such communications.
        </p>
        <h3 className="heading">6. Children's Privacy</h3>
        <p className="policyTxt">
          Our Website is not intended for children under the age of 13, and we
          do not knowingly collect personal information from children under the
          age of 13. If you believe that we have inadvertently collected
          information from a child under 13, please contact us immediately.
        </p>
        <h3 className="heading">7. Changes to this Privacy Policy</h3>
        <p className="policyTxt">
          We may update this Privacy Policy from time to time by posting the
          revised version on the Website. Your continued use of the Website
          after the effective date of the revised Privacy Policy constitutes
          acceptance of the terms.
        </p>
        <h3 className="heading">7. Contact Us</h3>
        <p className="policyTxt">
          If you have any questions or concerns about this Privacy Policy or our
          data practices, please contact us at [contact email].
        </p>
        <p className="policyTxt">
          By using our Website, you consent to the collection, use, and
          disclosure of your information as described in this Privacy Policy.
        </p>
      </Container>
      <br />
      <br />
      <Footer />
    </>
  );
};

export default TermsAndConditions;

// PrivacyPolicy.defaultProps = {
//   country: "in",
//   pageSize: 16,
//   category: "general",
// };

// PrivacyPolicy.propTypes = {
//   country: PropTypes.string,
//   pageSize: PropTypes.number,
//   category: PropTypes.string,
// };
