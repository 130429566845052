import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { LuMenu } from "react-icons/lu";
import { IoClose } from "react-icons/io5";
import "./../styles/navbar.css";
// import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { HiOutlineMail } from "react-icons/hi";
import * as EmailValidator from "email-validator";
import { ToastContainer, toast, Bounce } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { addData } from "../apis";

const Navbar = () => {
  const location = useLocation();
  let { pathname } = location;
  const [isVisible, setIsVisible] = useState(false);
  const [modalShow, setModalShow] = useState(false);

  const toggleVisibility = () => {
    setIsVisible(!isVisible);
  };

  function MyVerticallyCenteredModal(props) {
    const [email, setEmail] = useState("");

    const subscribeHandle = async () => {
      if (email === "") {
        toast.error("Email is required", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Bounce,
        });
      } else if (!EmailValidator.validate(email)) {
        toast.error("Email is invalid", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Bounce,
        });
      } else {
        try {
          await addData("email", { email });
          setEmail("");
          props.onHide();
          toast.success("Subscribed successfully", {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            transition: Bounce,
          });
        } catch (err) {
          toast.error(err.message | "something went wrong", {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            transition: Bounce,
          });
        }
      }
    };
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <section className="subscribe" id="subscribe">
            <h2 className="_subscribe_title">
              Don't Miss Out on New Jobs and Scholarships!
            </h2>
            <p>
              Subscribe to our newsletter and never miss an update on the latest
              jobs and scholarships.
            </p>
            <p>We'll help you to grow your career and growth.</p>
            <Row className="justify-content-center mt-10">
              <Col lg="5" md="5" sm="12" xs="12">
                <div
                  className="input_container"
                  style={{
                    backgroundColor: "white",
                    borderRadius: 10,
                    paddingLeft: 20,
                    height: 60,
                    margin: 2,
                    width: "100%",
                  }}
                >
                  <HiOutlineMail />

                  <input
                    placeholder="Enter your email"
                    className="_search_input"
                    style={{
                      backgroundColor: "white",
                      borderRadius: 10,
                    }}
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
              </Col>
              <Col lg="2" md="2" sm="12" xs="12">
                <button
                  onClick={subscribeHandle}
                  className="_subscribe_btn_home"
                  style={{ width: "100%" }}
                >
                  Subscribe
                </button>
              </Col>
            </Row>
          </section>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
    );
  }

  return (
    <>
      <div className="navbar">
        <Link to="/">
          <img src={require("../images/logo.png")} className="logo" />
        </Link>
        <div className="navbar_container">
          <Link
            to="/"
            className={pathname === "/" ? "active_nav_link" : "nav_link"}
          >
            Home
          </Link>
          <Link
            to="/jobs"
            className={pathname === "/jobs" ? "active_nav_link" : "nav_link"}
          >
            Jobs
          </Link>
          <Link
            to="/scholorships"
            className={
              pathname === "/scholorships" ? "active_nav_link" : "nav_link"
            }
          >
            Scholarships
          </Link>
          <Link
            to="/about"
            className={pathname === "/about" ? "active_nav_link" : "nav_link"}
          >
            About Us
          </Link>
          <Link
            to="/contact"
            className={pathname === "/contact" ? "active_nav_link" : "nav_link"}
          >
            Contact Us
          </Link>
          {/* <Link
          to="/privacy-policy"
          className={
            pathname === "/privacy-policy" ? "active_nav_link" : "nav_link"
          }
        >
          Privacy Policy
        </Link> */}
          <button className="_subscribe_btn" onClick={() => setModalShow(true)}>
            Subscribe
          </button>
        </div>

        <div className="menu_btn" onClick={toggleVisibility}>
          <LuMenu color="#171717" size={30} />
        </div>
        <div className={`navbar_container2 ${isVisible ? "visible" : ""}`}>
          <IoClose
            color="#171717"
            size={30}
            onClick={toggleVisibility}
            style={{
              // position: "absolute",
              alignSelf: "flex-end",
              display: "flex",
              marginTop: 10,
              marginRight: 10,
            }}
          />
          <Link
            to="/"
            className={pathname === "/" ? "active_nav_link" : "nav_link"}
            onClick={() => toggleVisibility()}
          >
            Home
          </Link>

          <Link
            to="/jobs"
            className={pathname === "/jobs" ? "active_nav_link" : "nav_link"}
            onClick={() => toggleVisibility()}
          >
            Jobs
          </Link>

          <Link
            to="/scholorships"
            className={
              pathname === "/scholorships" ? "active_nav_link" : "nav_link"
            }
            onClick={() => toggleVisibility()}
          >
            Scholarships
          </Link>

          <Link
            to="/about"
            className={pathname === "/about" ? "active_nav_link" : "nav_link"}
            onClick={() => toggleVisibility()}
          >
            About Us
          </Link>
          <Link
            to="/contact"
            className={pathname === "/contact" ? "active_nav_link" : "nav_link"}
            onClick={() => toggleVisibility()}
          >
            Contact Us
          </Link>
        </div>
        <MyVerticallyCenteredModal
          show={modalShow}
          onHide={() => setModalShow(false)}
        />
      </div>
      <ToastContainer />
    </>
  );
};

export default Navbar;
