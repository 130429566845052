import React, { useEffect, useState, useContext } from "react";
// import Loading from "./Loading";
// import Newsitems from "./Newsitems";
import PropTypes from "prop-types";
import InfiniteScroll from "react-infinite-scroll-component";
import JobItem from "../../components/JobItem";
import Loading from "../../components/Loading";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Filter from "../../components/Filter";
import "../../styles/home.css";
import { addData, getjobs } from "../../apis";
import { DataContext } from "../../context/DataContext";
import Footer from "../../components/Footer";
import { Helmet } from "react-helmet";
import { CiSearch } from "react-icons/ci";
import { CiLocationOn } from "react-icons/ci";
import { LuSearch } from "react-icons/lu";
import { HiOutlineMail } from "react-icons/hi";
import { FaArrowRightLong } from "react-icons/fa6";
import { CgScrollV } from "react-icons/cg";
import { MdOutlineKeyboardDoubleArrowDown } from "react-icons/md";
import * as EmailValidator from "email-validator";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast, Bounce } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AdsComponent from "../../components/AdsComponent";
import { BsBorderWidth } from "react-icons/bs";

const Home = (props) => {
  const navigate = useNavigate();
  const [articles, setArticles] = useState([]);
  const [loading, setLoading] = useState(true);
  const [totalResults, setTotalResults] = useState(0);
  const { jobs, setjobs } = useContext(DataContext);
  const [email, setEmail] = useState("");
  const [search, setSearch] = useState("");

  const popularSearches = [
    "Full-time Job",
    " Part-time",
    "Remote",
    "Internship",
    "Contract",
    "Freelance",
    "Education",
    "Merit-based",
    "Need-based",
    "Women Scholarships",
    "Minority Scholarships",
    "International Scholarships",
    "Graduate Scholarships",
    "Undergraduate Scholarships",
    "Study Abroad",
  ];

  useEffect(() => {
    fecthJobs();
  }, []);

  const fecthJobs = async () => {
    try {
      const data = await getjobs();
      console.log("========:", data);
      setjobs(data); // Assuming data.data contains the jobs array
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log("Error fetching jobs:", error);
    }
  };

  const subscribeHandle = async () => {
    if (email === "") {
      toast.error("Email is required", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
      });
    } else if (!EmailValidator.validate(email)) {
      toast.error("Email is invalid", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
      });
    } else {
      try {
        await addData("email", { email });
        toast.success("Subscribed successfully", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Bounce,
        });
        setEmail("");
      } catch (err) {
        toast.error(err.message | "something went wrong", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Bounce,
        });
      }
    }
  };

  // const notify = () =>

  return (
    <>
      <Helmet>
        <title>Find Your Dream Job and Top Scholarships | AppointTalent</title>
        <meta
          name="description"
          content="Discover the latest job openings and scholarship opportunities on AppointTalent. Your go-to platform for career and educational resources."
        />
        <meta
          name="keywords"
          content="job openings, latest jobs, career opportunities, job search, employment opportunities, find a job, job listings, apply for jobs, job vacancies, remote jobs, part-time jobs, full-time jobs, work from home jobs, internships, scholarships, scholarship opportunities, apply for scholarships, education funding, scholarship search, financial aid, college scholarships, university scholarships, graduate scholarships, international scholarships, scholarship programs, career advice, educational resources, job tips, resume tips, interview tips, professional development, student resources, career insights, education tips, professional networking"
        />
      </Helmet>

      <Container>
        <section className="_home_top_section">
          <Row className="justify-content-center mt-10">
            <Col lg="8" md="12" sm="12" xs="12">
              <div className="_home_heading">
                <h1>Find Your Dream Job and Top Scholarships</h1>
                <p>
                  Welcome to AppointTalent, your go-to platform for discovering
                  the latest job openings and scholarship opportunities. Start
                  your journey towards a successful career and academic
                  excellence today!
                </p>

                <div className="_search_row">
                  <div
                    className="input_container"
                    style={{
                      backgroundColor: "white",
                      borderRadius: 10,
                      paddingLeft: 20,
                      height: 60,
                      margin: 2,
                    }}
                  >
                    <CiSearch />
                    <input
                      placeholder="Search for jobs or scholarships..."
                      className="_search_input"
                      style={{ backgroundColor: "white", borderRadius: 10 }}
                      value={search}
                      onChange={(e) => setSearch(e.target.value)}
                    />
                  </div>

                  <button
                    className="_search_btn_home"
                    disabled={search === ""}
                    onClick={() =>
                      navigate(`/search?q=${search.toLowerCase()}`)
                    }
                  >
                    Search
                  </button>
                </div>
                <h6 className="_search_options_title">Popular Searches:</h6>
                {/* <LuSearch /> */}
                <section>
                  <div className="_tags">
                    {popularSearches.map((tag, i) => {
                      return (
                        <label
                          key={i}
                          className="_kewords"
                          onClick={() =>
                            navigate(`/search?q=${tag.toLowerCase()}`)
                          }
                        >
                          <LuSearch /> {tag}
                        </label>
                      );
                    })}
                  </div>
                </section>
                <br />
                <br />
              </div>
            </Col>
          </Row>
          <div className="scroll-icon bottom">
            <MdOutlineKeyboardDoubleArrowDown />
          </div>
        </section>
      </Container>

      <br />
      <br />
      <div className="_home_body_section">
        <Container>
          <section>
            <section className="features">
              <Row className="justify-content-center mt-10">
                <Col lg="4" md="4" sm="12" xs="12">
                  <div className="feature-item">
                    <div className="feature-item-row">
                      <div className="feature-icon">
                        <img
                          src={require("../../images/findjob.png")}
                          alt="Jobs Icon"
                        />
                      </div>
                      <h5>Latest Job Listings</h5>
                    </div>
                    <p>
                      Stay updated with the newest job openings in various
                      industries.
                    </p>
                  </div>
                </Col>
                <Col lg="4" md="4" sm="12" xs="12">
                  <div className="feature-item">
                    <div className="feature-item-row">
                      <div className="feature-icon">
                        <img
                          src={require("../../images/scholorships.png")}
                          alt="Scholarships Icon"
                        />
                      </div>
                      <h5>Exclusive Scholarships</h5>
                    </div>
                    <p>
                      Find scholarships that can help you fund your education.
                    </p>
                  </div>
                </Col>
                <Col lg="4" md="4" sm="12" xs="12">
                  <div className="feature-item">
                    <div className="feature-item-row">
                      <div className="feature-icon">
                        <img
                          src={require("../../images/job.png")}
                          alt="Resources Icon"
                        />
                      </div>
                      <h5>Career Resources</h5>
                    </div>

                    <p>
                      Access valuable resources to boost your career and
                      education.
                    </p>
                  </div>
                </Col>
              </Row>
            </section>
          </section>

          <section>
            <h6 className="_view_all" onClick={() => navigate("/search")}>
              View All
              <FaArrowRightLong />
            </h6>

            <div
              style={{
                width: "100%",
                border: "1px solid grey",
                height: 90,
                color: "grey",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              advertisement
              {/* <AdsComponent
              client="ca-pub-3768976581935456"
              slot="3401941690"
              format="auto"
              layout="fixed"
              style={{display:"block", border: "1px solid grey"}}
              className="adsbygoogle"
            /> */}
            </div>

            <Row className="justify-content-center mt-10">
              {jobs ? (
                jobs.map((element, index) => {
                  return (
                    <Col lg="12" md="6" sm="12" xs="12" key={index}>
                      <JobItem data={element} key={index} />
                    </Col>
                  );
                })
              ) : (
                <div className="_job_not_found">Job Not Found</div>
              )}
            </Row>

            <br />
            <br />
            <br />
            <br />
          </section>
        </Container>
      </div>
      <br />
      <br />

      <Container>
        <section className="subscribe" id="subscribe">
          <h2 className="_subscribe_title">
            Don't Miss Out on New Jobs and Scholarships!
          </h2>
          <p>
            Subscribe to our newsletter and never miss an update on the latest
            jobs and scholarships.
          </p>
          <p>We'll help you to grow your career and growth.</p>
          {/* <AdsComponent
            client="ca-pub-3768976581935456"
            slot="1771534940"
            format="auto"
            className="adsbygoogle"
            style={{
              display: "inline-block",
              width: "728px",
              height: "90px",
              border: "1px solid grey",
            }}
          /> */}

          <Row className="justify-content-center mt-10">
            <Col lg="5" md="5" sm="12" xs="12">
              <div
                className="input_container"
                style={{
                  backgroundColor: "white",
                  borderRadius: 10,
                  paddingLeft: 20,
                  height: 60,
                  margin: 2,
                  width: "100%",
                }}
              >
                <HiOutlineMail />

                <input
                  placeholder="Enter your email"
                  className="_search_input"
                  style={{
                    backgroundColor: "white",
                    borderRadius: 10,
                  }}
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
            </Col>
            <Col lg="2" md="2" sm="12" xs="12">
              <button
                onClick={subscribeHandle}
                className="_subscribe_btn_home"
                style={{ width: "100%" }}
              >
                Subscribe
              </button>
            </Col>
          </Row>
        </section>
      </Container>

      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />

      <Footer />
      <ToastContainer />
    </>
  );
};

export default Home;

Home.defaultProps = {
  country: "in",
  pageSize: 16,
  category: "general",
};

Home.propTypes = {
  country: PropTypes.string,
  pageSize: PropTypes.number,
  category: PropTypes.string,
};
